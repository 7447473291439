import { gql } from '@apollo/client';

export const PRODUCT_REVIEW = gql`
{
  productReview {
    rating
    numberOfReviews
  }
}
`;
