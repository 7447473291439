import { gql } from '@apollo/client';

export const GOOGLE_REVIEW = gql`
{
   googlePlaceSearch {
     rating
     userRatingsTotal
   }
 }
`;
