const SDK_CLIENT_KEY_MISSING_ERROR = 'Segment CDN URL and Write Key are required';

enum AnalyticsPage {
  Homepage = 'Homepage',
  BusinessDirectory = 'Business Directory',
  BusinessDirectoryCategoryPage = 'Business Directory Category Page',
  BusinessDirectoryLocationPage = 'Business Directory Location Page',
  BusinessAwards = 'Business Awards',
  CostGuideCentre = 'Cost Guide Centre',
  CostGuidePage = 'Cost Guide Page',
  InspirationCentre = 'Inspiration Centre',
  InspirationArticle = 'Inspiration Article',
  WinWorkGuide = 'Win work guide',
  AboutUs = 'About Us',
  Reviews = 'Reviews',
  MyJobs = 'My Jobs',
  ContactUs = 'Contact Us',
  BusinessProfile = 'Business Profile',
  BusinessCentre = 'Oneflare Business Centre'
}

enum AnalyticsJobFormSection {
  HeroBanner = 'Hero Banner',
  HowOneflareWorks = 'How Oneflare Works',
  GetQuotesNow = 'Get Quotes Now',
  FinalBanner = 'Final Banner',
  DirectoryListing = 'Directory Listing',
  BusinessListing = 'Business Listing',
  NavigationBar = 'Navigation Bar',
  WhatsNextOnYourToDoList = "What's next on your to-do list?"
}

enum AnalyticsJobFormCtaText {
  GetFreeQuotes = 'Get free quotes',
  GetQuotes = 'Get quotes',
  GetQuotesNow = 'Get quotes now',
  RequestQuotes = 'Request quotes',
  Go = 'Go',
  PostAJob = 'Post a job'
}

export {
  AnalyticsPage,
  AnalyticsJobFormSection,
  AnalyticsJobFormCtaText,
  SDK_CLIENT_KEY_MISSING_ERROR
};
